<template>
  <img
    v-if="imageSrc"
    :src="imageSrc"
    :alt="currency"
    width="10"
    class="w-auto"
  >
  <template v-else-if="currency === 'USD'">$</template>
</template>

<script setup>
const icons = import.meta.glob('@/assets/icons/*.svg', { eager: true, });

const props = defineProps({
  currency: {
    type: String,
    required: true,
  },
});

const imageSrc = computed(() => {
  const iconImport = icons[`/assets/icons/${props.currency}.svg`];

  if (!iconImport) { return null; }

  return iconImport.default;
});
</script>
